<script lang="ts" setup>
const props = withDefaults(
	defineProps<{
		withTooltip: boolean;
		collapsable?: boolean;
	}>(),
	{
		withTooltip: true,
		collapsable: false
	}
);

const showTooltip = ref(false);
const popperRef = ref(null);
const isShowWinnings = ref(false);

onClickOutside(popperRef, () => {
	showTooltip.value = false;
});

const { isOpen } = useAppModals();
const isGuest = useIsGuest();
const { isSweepStakes, handleSwitchGameMode } = useSwitchMode();
const { winnings, entries, tourPoints } = useBalance({
	options: { cached: true, immediate: !isGuest.value, watch: [isGuest] }
});

const isGameModal = computed(() => isOpen("LazyOModalGame"));

const handleSwitchToMode = async () => {
	dispatchGAEvent({
		event: "click_button",
		location: "header",
		button_name: isSweepStakes.value ? "switch_tourney" : "switch_promotional"
	});

	await handleSwitchGameMode();

	if (props.withTooltip) {
		showTooltip.value = true;
	}
};

const toggleWinnings = () => (isShowWinnings.value = !isShowWinnings.value);
</script>
<template>
	<div
		class="header-balance"
		data-tid="header-balances"
		:class="{ 'is-blurred': isGameModal }"
		@click="handleSwitchToMode"
	>
		<div class="balance-toggle">
			<AToggle :model-value="isSweepStakes" color="var(--canberra)" :size="12" />
		</div>

		<div class="balance-wrapper" :class="{ collapsable }">
			<div class="balance-coins">
				<AText
					as="div"
					variant="tempe"
					:modifiers="['semibold']"
					class="balance-item text-coyoacan text-tlalnepantla"
					:data-tid="`active-${!isSweepStakes}`"
					:class="{ active: !isSweepStakes }"
				>
					<NuxtImg
						src="/nuxt-img/prizes/funcoins.png"
						srcset="/nuxt-img/prizes/funcoins@2x.png"
						class="balance-coin-img"
						width="12"
						height="12"
						alt="Funcoins prize"
						format="avif"
					/>
					<AAnimationNumberCounter class="value" :number="tourPoints" data-tid="points-coins" />
				</AText>

				<AText
					as="div"
					variant="tempe"
					:modifiers="['semibold']"
					class="balance-item text-сirebon text-tlalnepantla"
					:data-tid="`active-${isSweepStakes}`"
					:class="{ active: isSweepStakes }"
				>
					<NuxtImg
						src="/nuxt-img/prizes/citycoins.png"
						srcset="/nuxt-img/prizes/citycoins@2x.png"
						class="balance-coin-img"
						width="12"
						height="12"
						alt="Citycoins prize"
						format="avif"
					/>

					<AAnimationNumberCounter class="value" :number="entries" data-tid="entries-coins" />

					<AText as="div" :modifiers="['semibold']" variant="topeka" class="winnings">
						<i18n-t keypath="Win. {winnings}">
							<template #winnings>
								<AText variant="topeka" :modifiers="['semibold']" class="value text-cannes">
									<AAnimationNumberCounter :number="winnings" :decimals="2" data-tid="winnings-coins" />
								</AText>
							</template>
						</i18n-t>
					</AText>
				</AText>
			</div>

			<div v-if="collapsable" class="toggle-winnings" @click.stop="toggleWinnings">
				<NuxtIcon name="16/arrow-down-small" class="icon-arrow" :class="{ 'winnings-opened': isShowWinnings }" filled />
			</div>
		</div>
	</div>
</template>
<style scoped lang="scss">
@keyframes balanceBlur {
	from {
		filter: blur(0);
	}
	to {
		filter: blur(4px);
	}
}
.header-balance {
	display: flex;
	gap: gutter(1.25);
	height: 40px;
	cursor: pointer;

	&.is-blurred {
		animation: balanceBlur 0.2s linear 5s forwards;

		&:hover {
			animation: none;
		}
	}
}

.balance-wrapper {
	border-radius: 4px;
	width: 100%;
	max-width: 150px;
	border: 1px solid var(--сirebon);
	background-color: var(--chifeng);
	display: flex;
	justify-content: space-between;
	padding: gutter(0.5) gutter(1) gutter(0.5) gutter(0.5);
	transition: all 0.2s;
	position: relative;
	overflow: hidden;

	.balance-coins {
		display: flex;
		flex-direction: column;
		justify-content: space-between;
	}

	.toggle-winnings {
		position: absolute;
		right: 0;
		top: 0;
		bottom: 0;
		display: flex;
		justify-content: center;
		align-items: center;
		background-color: var(--cannes);
		border: 1px solid var(--canberra);
		border-radius: 5px;
		width: 18px;

		.icon-arrow {
			transition: all 0.2s;
			rotate: 90deg;

			&.winnings-opened {
				transform: rotateX(180deg);
			}
		}
	}

	&.collapsable {
		max-width: 115px;
		.winnings {
			opacity: 0;
		}

		&:has(.winnings-opened) {
			max-width: 195px;
			padding-right: gutter(2.5) !important;
			.winnings {
				opacity: 1;
			}
		}
	}
}

.balance-item {
	height: 16px;
	display: flex;
	align-items: center;
	gap: gutter(0.25);
	opacity: 0.5;
	line-height: 14px;

	&:deep(.nuxt-icon) {
		font-size: 18px;
	}

	&.active {
		opacity: 1;
	}

	.value {
		display: inline-block;
	}
}

.balance-coin-img {
	margin: gutter(0.375);
}

.winnings {
	margin-left: gutter(0.5);
	color: var(--costa);
	flex-shrink: 0;
	transition: opacity 0.2;
	opacity: 1;
}

.balance-toggle {
	position: relative;
	&:deep(.inner) {
		width: 16px;
		height: 40px;

		.track {
			opacity: 1;
			background-color: var(--chifeng);
			border: 1px solid var(--сirebon);
		}

		.thumb {
			width: 20px;
			height: 20px;
			top: -1px;
			left: -2px;
			transform: none;
			transition: 0.2s;

			&::after {
				background: url("~/assets/icons/12/coins.svg") center/32px no-repeat;
			}
		}

		&.truthy .thumb {
			top: calc(100% - 19px);

			&::after {
				background: url("~/assets/icons/12/secret-coins.svg") center/32px no-repeat;
			}
		}
	}

	&:deep(.popper) {
		width: 260px;
		right: -37px !important;

		> #arrow {
			transform: none !important;
			left: auto !important;
			right: 35px !important;
		}
	}

	.popper-content {
		p {
			margin: gutter(2) 0 gutter(0.75);
		}

		span {
			line-height: 20px;
		}
	}
}
</style>
